<template>
  <div class="goodsActivity">
    <!-- <el-button @click="getList" type="primary">主要按钮</el-button> -->
    <slideshow :slideshowList="slideshowList"></slideshow>
    <img class="debris" src="../../../static/debris.png" />
    <img
      class="Aboutbg"
      style="top: 43%; right: 0"
      src="../../../static/crane.png"
    />
    <img
      class="Aboutbg"
      style="top: 35%; right: 0"
      src="../../../static/crane.png"
    />
    <img
      class="Aboutbg"
      style="bottom: 18%; right: 0"
      src="../../../static/crane.png"
    />
    <img
      class="Aboutbg"
      style="top: 28%; left: 0"
      src="../../../static/wasteL.png"
    />
    <img
      class="Aboutbg"
      style="top: 56%; left: 0"
      src="../../../static/wasteL.png"
    />
    <img
      class="Aboutbg"
      style="bottom: 0; left: 0"
      src="../../../static/wasteL.png"
    />
    <img
      class="Aboutbg"
      style="top: 16%; right: 0"
      src="../../../static/wasteR.png"
    />
    <img
      class="Aboutbg"
      style="top: 53%; right: 0"
      src="../../../static/wasteR.png"
    />
    <img
      class="Aboutbg"
      style="bottom: 3%; right: 0"
      src="../../../static/wasteR.png"
    />
    <el-container>
      <el-main>
        <div>
          <div id="general" class="general" style="padding: 120px 80px 0 80px">
            <div style="width: 100%">
              <!-- <hr /> -->
              <div class="top-title">
                <img class="home-icon" src="../../../static/scenery/home.png" alt="" style="width:20;height:20;">
                <span style="margin-right:6px;font-weight:bold" @click="Jump('/',0)">首页</span>
                >
                <span style="margin-left:6px;" @click="Jump('/essential')">条子泥攻略</span>
                >
                <span style="margin-left:6px;color:#509AB1">酒店住宿</span>
                <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
                  <el-breadcrumb-item
                    :to="{ path: '/' }"
                    @click.native="$store.commit('active', 0)"
                  >
                    <img
                      class="home-icon"
                      src="../../../static/scenery/home.png"
                      alt=""
                      style="width: 20; height: 20"
                    />
                    首页</el-breadcrumb-item
                  >
                  <el-breadcrumb-item>条子泥攻略</el-breadcrumb-item>
                  <el-breadcrumb-item>酒店住宿</el-breadcrumb-item>
                </el-breadcrumb> -->
              </div>
              <div class="hr-more">
                <div>酒店住宿</div>
              </div>
              <!-- <div class="tabs">
                <div
                  @click="activeNumApi(0)"
                  :class="activeNum ? 'tabsB' : ''"
                >
                  周边住宿
                </div>
                <div
                  @click="activeNumApi(1)"
                  :class="activeNum ? '' : 'tabsB'"
                >
                  预留景区住宿
                </div>
              </div> -->
              <div>
                <div class="QRcode" v-for="(item,index) in hotelAccommodationList" :key="item.id">
                  <div
                    v-if=" index%2 == 0 "
                    class="QRcodebg"
                    :style="{background: 'url(' + item.picUrl + ')  no-repeat center','background-size': 'cover'}"
                  >
                    <img src="../../../static/tabsbg03.png" style="opacity:0.7" alt="" />
                  </div>
                  <div class="QRcodebg2">
                    <h4>{{item.hotelName}}<el-button v-if="item.toUrl" @click.native="goProject(item.toUrl)" style="background-color: #254f63;margin-left: 30px;" type="primary">立即预定</el-button></h4>
                    <h5>电话：{{item.telphone}}</h5>
                    <h5>
                      地址：{{item.hotelAddress}}
                    </h5>
                    <p>
                      简介：{{item.hotelContent}}
                    </p>
                  </div>
                  <div
                    v-if=" index%2 != 0 "
                    class="QRcodebg"
                    :style="{background: 'url(' + item.picUrl + ')  no-repeat center','background-size': 'cover'}"
                  >
                    <img src="../../../static/tabsbg03.png" style="opacity:0.7" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
        <el-pagination
          background
          prev-text="首页"
          next-text="下一页"
          @prev-click = "prevClick"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="5"
          layout="prev, pager, next, slot"
          :total="count"
        >
        </el-pagination>
        </div>
        <div
          ref="backTopBG"
          style=" 
            position: fixed;
            display: none;
            right: 56px;
            color: #509ab1;
            font-weight: bold;
            text-align: center;
            bottom: 130px;
            z-index: 9;
          "
          @click="backTop()"
        >
          <img src="../../../static/backTopbg.png" alt="" />
          <p>回到顶部</p>
        </div>
        <leftFloat :floatList="floatList" />
      </el-main>
    </el-container>
  </div>
</template>

<script>
import * as api from "@/api/test/test";
export default {
  name: "About",
  data() {
    return {
      floatList: {
                list: [
                { name: "基本信息", url: "/essential" },
                { name: "舌尖美味", url: "/foodList" },
                { name: "酒店住宿", url: "flush" },
                { name: "景区互动", url: "/interaction" },
                { name: "行程推荐", url: "/travel" },
                ],
                title: "条子泥攻略",
            },
      currentPage: 1,
      hotelType:0,
      activeNum: false,
      slideshowList: ``,
      hotelAccommodationList:``,
      count:5
    };
  },
  watch:{
    activeNum(n,o){
      console.log(22222);
      this.currentPage = 1
    }
  },
  methods: {
     goProject(src) {
            window.open(src, "_blank");
        },
    activeNumApi(e, page ){
      if(e == 1){
        this.activeNum = true
        this.hotelType = 1
      }else if(e == 0){
        this.activeNum = false
        this.hotelType = 0
      }
      this.$nextTick(()=>{
        api.hotelAccommodation({hotelType: this.hotelType, size: 5, page: this.currentPage - 1}).then(({data,count}) => {
          this.hotelAccommodationList = data
          this.count = count
        })
      });
    },
    backTop() {
      const that = this;
      let ispeed = Math.floor(that.scrollTop / 5);
      document.documentElement.scrollTop = document.body.scrollTop =
        that.scrollTop + ispeed;
      if (that.scrollTop === 0) {
        clearInterval(timer);
      }
    },
    //分页
    prevClick(val){
      this.currentPage = 1
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.activeNumApi()
    },
  },
  created() {
    api.slideshow().then((res) => {
      this.slideshowList = res;
    });
    this.activeNumApi()
  },
  mounted() {
  },
  destroyed() {
  },
};
</script>
<style scoped>
.el-pagination {
  display: flex;
  justify-content: center;
  margin: 18px 0 51px;
}
.QRcode {
  width: 65%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
.QRcode > .QRcodebg {
  width: 340px;
  height: 340px;
  border-radius: 50%;
}
.QRcodebg2 {
  box-sizing: border-box;
  padding: 35px 60px;
  /* width: 816px;
  height: 392px;
  background: url(../../../static/scenicSpotBg.png) no-repeat; */
}
.QRcodebg2 > h4 {
  margin: 10px 0;
}
.QRcode > div > P {
  text-indent: 16px;
  margin: 10px 0;
  background: #fbeab9;
  padding: 14px;
  border-radius: 28px;
  font-family: 'tzn-font';
  width: 600px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.PastPhotos {
  background: url(../../../static/PastPhotosbg.png) no-repeat;
  height: 737px;
}
.imgBox {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  /* height: 950px;
  overflow-y: auto; */
}
>>> .el-breadcrumb {
  line-height: 92px;
  font-size: 24px;
}
.top-title {
  background-size: cover;
  background-image: url(/static/img/title.ab49d158.png);
  background-repeat: no-repeat;
  letter-spacing: 5px;
  width: 1200px;
  margin: 0 auto;
  text-align: left;
  line-height: 92px;
  font-size: 24px;
}
.home-icon {
  width: 20px;
  height: 20px;
  margin: 0 16px 0 53px;
}
.top-title>span:hover{
    cursor: pointer;
    color: #509AB1;
}
.morebg {
  display: block;
  width: 201px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  color: #509ab1;
  margin: 0 auto;
  background: url("../../../static/morebg.png") no-repeat;
}
.tabs {
  display: flex;
  justify-content: center;
}
.tabs > div {
  width: 230px;
  height: 59px;
  line-height: 59px;
  text-align: center;
  margin: 15px;
  font-size: 23px;
  color: #509ab1;
  background: url("../../../static/tab.png") no-repeat;
}
.tabsB {
  background: url("../../../static/tabAcitve.png") no-repeat !important;
  background-size: cover !important;
  color: #f6d397 !important;
}
.Hrbg2 {
  display: flex;
  justify-content: space-evenly;
  width: 1241px;
  height: 151px;
  line-height: 135px;
  text-align: center;
  margin: 0 auto;
  font-size: 24px;
  font-family: PangMenZhengDao-Cu;
  font-weight: bold;

  color: #509ab1;
  background: url("../../../static/Hrbg3.png") no-repeat;
}
.Hrbg2 > div > img {
  vertical-align: middle;
  padding-right: 10px;
}
.Aboutbg {
  position: absolute;
  z-index: -1;
}
.introduce_tabs {
  display: flex;
  justify-content: space-evenly;
  text-align: center;
}
.introduce_tabs p,
.textSpan {
  font-size: 25px;
  font-family: PangMenZhengDao-Cu;
  font-weight: bold;
  color: #509ab1;
}
.debris {
  width: 100%;
  margin-top: -157px;
  z-index: 8;
  background: transparent;
  position: relative;
}
.active {
  background-color: #509ab1;
}
.greatDeeds_r h3 {
  width: 55px;
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #509ab1;
  line-height: 41px;
}
.greatDeeds_r h5 {
  width: 357px;
  height: 18px;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
}
.greatDeeds_r > div {
  position: absolute;
  left: -50vw;
  width: 100vw;
  display: flex;
  justify-content: space-evenly;
}
.greatDeeds_bzt {
  height: 100%;
  position: relative;
  left: 50%;
  width: 2px;
  border: none;
  background: darkgray;
}
.greatDeeds_bzt > h2 {
  position: absolute;
  right: -19px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #509ab1;
  border-radius: 25px;
  text-align: center;
}
img[class^="general"] {
  position: absolute;
  z-index: -99;
}
.greatDeeds {
  background: url("../../../static/greatDeedsimg.png") no-repeat center;
  background-size: cover;
}
.Hcontent {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px 160px;
  background: url("../../../static/img3bg.png") no-repeat 100% 100%;
  background-size: cover;
  height: 600px;
}
.content-text {
  width: 142px;
  height: 231px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: url("../../../static/img13.png") no-repeat 100% 100%;
}
.content-text > p {
  width: 30px;
  font-size: 21px;
  font-weight: bold;
  line-height: 29px;
  color: #317981;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* 静态资源 */
.content-grid {
  width: 220px;
  height: 320px;
  z-index: 12;
  position: relative;
}
.introduceText {
  white-space: pre-wrap;
  display: -webkit-box;
  -webkit-line-clamp: 11;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 10px;
}
.introduce {
  background-size: cover;
}
.el-tabs__content > div {
  display: flex;
}
.greatDeeds_2 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.greatDeeds_2 > div {
  padding-left: 40px;
  background-position-y: 50%;
}

>>> .el-tabs__content {
  height: 300px;
}
.hr-more {
  white-space: nowrap;
  letter-spacing: 3px;
  line-height: 250px;
  width: 452px;
  height: 250px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  top: -25px;
  font-weight: bold;
  vertical-align: middle;
  text-align: center;
  background: url("../../../static/Hrbg.png");
  font-size: 35px;
  font-family: PangMenZhengDao-Cu;
  font-weight: 400;
  color: #509ab1;
  text-shadow: ;
}
.hr-more img {
  margin: 0 6px;
}
.hr-more span {
  width: 119px;
  height: 9px;
  font-size: 12px;
  font-family: Arial;
  font-weight: 400;
  color: #b3b3b3;
  line-height: 24px;
}
.goodsActivity {
  position: relative;
}
.general {
  display: flex;
  /* box-shadow: 0px -7px 18px 0px rgb(0 0 0 / 10%); */
  padding-left: 20px;
  margin-bottom: 50px;
  /* background: url("../../../static/generalbg.png") no-repeat -6% -49%; */
}

.el-main {
  padding: 0;
  overflow-x: hidden;
}
</style>